import axios from 'axios';
import {clearToken} from "./local-storage";
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import i18next from "i18next";
import React from "react";

let instance = axios.create({
        headers: {
            'Authorization': localStorage.getItem('token')||"not set",
            'X-Company':localStorage.getItem('company')||"not set"
        }
});

window.addEventListener("storage", function () {

    instance = axios.create({
        headers: {
            'Authorization': localStorage.getItem('token')||"not set",
            'X-Company':localStorage.getItem('company')||"not set"
        }
    });
}, false);

instance.interceptors.request.use(function (config) {
    // console.log("axios config", config)
    try {
        const token = localStorage.getItem("token");
        // console.log("token", token)

        if (token) {
            let decoded = jwt_decode(token);
            // console.log("decoded", decoded)
            // console.log("check time", Date.now() / 1000)

            if (Date.now() / 1000 > decoded.exp) {
                console.log("token expired");

                clearToken();
                window.location.href = '/login';
                window.location.reload(true);
            } else {
                // console.log("not expired")
            }
        }
    } catch(err) {
        console.log("no token", err)
    }

    return config;
}, function (error) {
    // console.log("axios error", error)
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    // console.log('intercep success', response);

    if (response.data.notif_message !== undefined && response.data.notif_message != null) {
        console.log('success notif', response.data.notif_message);
        toast.success(i18next.t(response.data.notif_message), {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    return response;
}, function (error) {
    console.log('intercep');
    console.log('intercep error', error.response);
    if (error.response && error.response.data.notif_message !== undefined && error.response.data.notif_message != null && !error.response.data.notif_message.includes('null')) {
        console.log('error notif', error.response.data.notif_message);
        toast.error(i18next.t(error.response.data.notif_message), {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    return Promise.reject(error);
});

export const axiosGet = (url) => {
    return instance.get(url);
};

export const axiosPost = (url, data) => {
    return instance.post(url, data);
};

export const axiosBlobPost = (url, data) => {
    return instance.post(url, data, {
        ...instance.config,
        responseType: 'blob'
    });
};

export const axiosPut = (url, data) => {
   return instance.put(url,data)
};

export const axiosDelete = (url, data) => {
    return instance.delete(url, {data:data})

};

